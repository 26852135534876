import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import './ga'
import './sentry'

import React from 'react'
import ReactDOM from 'react-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'

const styles = {
  applicationLoadingIndicatorWrapper: {
    minHeight: '100vh',
  },
}

const ApplicationLoadingIndicator = () => (
  <React.Fragment>
    <CssBaseline />

    <Grid
      alignItems="center"
      container
      justify="center"
      direction="column"
      spacing={0}
      style={styles.applicationLoadingIndicatorWrapper}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  </React.Fragment>
)

const ApplicationLazyLoader = React.lazy(() => import('./ApplicationContainer'))

const AsynchronousApplication = () => (
  <React.Suspense fallback={<ApplicationLoadingIndicator />}>
    <ApplicationLazyLoader />
  </React.Suspense>
)

ReactDOM.render(
  <AsynchronousApplication />,
  global.document.getElementById('root'),
)
