import * as SentryBrowser from '@sentry/browser'

const {
  NODE_ENV,
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_ENV,
  REACT_APP_SENTRY_VERSION,
} = process.env

/* eslint-disable no-console */
const SentryVoid = {
  captureException: (...params) =>
    NODE_ENV === 'development'
      ? console.log('Sentry.captureException:', params)
      : () => {},
  init: (...params) =>
    NODE_ENV === 'development' ? console.log('Sentry.init:', params) : () => {},
  setUser: (...params) =>
    NODE_ENV === 'development'
      ? console.log('Sentry.setUser:', params)
      : () => {},
}
/* eslint-enable no-console */

const Sentry = REACT_APP_SENTRY_DSN ? SentryBrowser : SentryVoid

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  release: REACT_APP_SENTRY_VERSION,
})

export default Sentry
